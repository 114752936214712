//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .link-list-wrapper {
    // classe modificatore per menu laterale
    &.menu-link-list {
      h3,
      h4 {
        color: $text-color;

        &:after {
          background-color: $primary;
        }
      }
    }
  }
}
