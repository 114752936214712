.argumentInEvidence {
  .argumentInEvidence-background {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($primary, 0.3);
      content: '';
    }
  }

  h2 {
    color: #fff;
  }

  .card {
    border-radius: 4px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    .card-body {
      p {
        margin-bottom: 0.8rem;
      }

      .card-text {
        margin-bottom: 2rem;
      }

      a:not(:hover) {
        text-decoration: none;
      }

      .category-top {
        .icon {
          padding: 0.5rem;
          background-color: #f0f9f6;
          border-radius: 50%;
          fill: $primary;
        }
      }

      a.read-more {
        text-decoration: none;

        &:hover {
          color: $mirandola-dark-blue;
          text-decoration: underline;

          .icon {
            fill: $mirandola-dark-blue;
          }
        }
      }
    }
  }
}
