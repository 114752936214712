.breadcrumb-container {
  .breadcrumb {
    .breadcrumb-item {
      a {
        color: $primary;
        font-weight: normal;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        a {
          color: $text-color;
        }
      }
      span.separator {
        padding: 0 0.3em;
        color: $primary;
        font-weight: normal;
      }
    }
  }
}
