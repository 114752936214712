body.subsite.subsite-root {
  &,
  .public-ui {
    .it-header-wrapper {
      .icon {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}
