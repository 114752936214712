.documentDescription {
  color: $text-color;
}

.page-sharing,
.page-actions {
  .dropdown-toggle {
    svg {
      fill: $primary;
    }
  }
}

.page-arguments {
  h6 {
    small {
      font-size: 14px;
    }
    color: #455a64;
  }
}
