$footer-underline-color: #7e97b8;

.it-footer-main {
  background-color: $secondary;

  a {
    color: $white;
    text-decoration-color: $footer-underline-color;

    &:hover {
      text-decoration-color: $white;
    }
  }

  h4 {
    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid $footer-underline-color;
    margin-right: 0.5em;
    font-weight: $font-weight-bold;

    svg.icon-sm {
      width: 18px;
      height: 18px;
      margin-right: 12px !important;
    }

    a {
      font-size: 14px;
    }
  }

  .contact-brand-name {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: $font-weight-bold;
  }

  .it-brand-wrapper {
    a {
      h2 {
        font-size: 2em;
      }

      h3 {
        font-size: 1em;
        font-weight: $font-weight-semibold;
      }

      .icon {
        width: $header-center-icon-size;
        height: auto;
        margin-right: $header-center-icon-margin;
      }
    }
  }

  .social {
    .list-inline-item:not(:last-child) {
      margin-right: 0.4rem;
    }
  }

  section.border-white.border-top {
    border: none !important;
  }
}

.it-footer-small-prints {
  background-color: $secondary;
  font-weight: 400;

  .container {
    border-top: 1px solid $footer-underline-color;

    a {
      &:hover {
        text-decoration: underline;
        text-decoration-color: $white;
      }
    }
  }
}
