.public-ui .login-button.btn-primary {
  background-color: $header-center-bg-color;
  &:hover,
  &:active,
  &:focus {
    background-color: $headerslim-bg-color;
  }

  &:focus {
    &:not(.disabled) {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem $header-center-bg-color;
    }
  }
}
