//site
$primary-h: 193.52 !default;
$primary-s: 89.87 !default;
$primary-b: 61.96 !default;

$secondary: #003883;
$tertiary: #107e9e;
//$tertiary-text: $white;
$mirandola-dark-blue: #003274;

$text-color: #333;
$headings-color: $text-color;
$body-color: $text-color;

$link-hover-color: $mirandola-dark-blue;
$link-hover-decoration: underline;

$header-bg-color: $secondary;
$header-center-bg-color: $header-bg-color;
$headerslim-bg-color: $mirandola-dark-blue;

$font-weight-semibold: 600;

$gray-border: #e5e5e5;

$argument-icon-color: $tertiary;
$argument-icon-bg: rgba($tertiary, 0.1);
