.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      a {
        .icon {
          width: 48px;
          height: 55px;
        }
      }
    }
  }
}
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            width: 85px;
            height: 98px;
          }
        }
      }
    }
  }
}
