.card {
  .card-body {
    .category-top {
      color: $text-color;

      a.category {
        color: $text-color;
      }
    }

    .card-title {
      a {
        line-height: 2rem;
        font-size: 1.32rem;
        color: $text-color;
      }
    }

    .card-text {
      color: $text-color;
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }

  a.read-more {
    color: $link-color;
  }
}
