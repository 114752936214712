.container .block {
  .searchSections {
    h2.text-secondary {
      color: #fff !important;
    }

    .searchSections-background {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.3);
        content: '';
      }
    }

    .searchContainer {
      .searchbar {
        button {
          background: $secondary;

          &:hover,
          &:active {
            background-color: darken($secondary, 7.5);
          }
        }
      }
    }

    .buttonsContainer {
      button.btn.btn-sm.btn-primary {
        @include button-variant($secondary, $secondary);
      }
    }
  }
}
